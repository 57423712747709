import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AggregateUnitsRevenueChart = ({ data }) => {
    const categories = data.map(item => item.yearMonth);
    const totalActiveRevenue = data.map(item => item.activeRevenue);
    const cumulativeActiveRevenue = data.map(item => item.cumulativeActiveRevenue);
    const totalActiveUnits = data.map(item => item.totalActiveUnits);
    const cumulativeActiveUnits = data.map(item => item.cumulativeActiveUnits);
    const totalInactiveUnits = data.map(item => item.totalInactiveUnits);
    const cumulativeInactiveUnits = data.map(item => item.cumulativeInactiveUnits);
    const inactiveRevenue = data.map(item => item.inactiveRevenue);
    const cumulativeInactiveRevenue = data.map(item => item.cumulativeInactiveRevenue);

    // Find the maximum value for units to scale the y-axis
    const maxUnits = Math.max(...totalActiveUnits, ...cumulativeActiveUnits);

    // Scale revenue values to match the units scale
    const scaleFactor = maxUnits / Math.max(...totalActiveRevenue, ...cumulativeActiveRevenue);
    const scaledTotalActiveRevenue = totalActiveRevenue.map(value => value * scaleFactor);
    const scaledCumulativeActiveRevenue = cumulativeActiveRevenue.map(value => value * scaleFactor);
    const scaledInactiveRevenue = inactiveRevenue.map(value => value * scaleFactor);
    const scaledCumulativeInactiveRevenue = cumulativeInactiveRevenue.map(value => value * scaleFactor);

    // Chart configuration
    const options: ApexOptions = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        xaxis: {
            categories: categories,
            title: {
                text: 'Month'
            }
        },
        yaxis: {
            title: {
                text: 'Units / Scaled Revenue'
            },
            min: 0,
            max: maxUnits,
            labels: {
                formatter: (value) => `${value?.toLocaleString()}`
            }
        },
        title: {
            text: 'Monthly Revenue and Units Analysis',
            align: 'center',
            style: {
                fontSize: '16px'
            }
        },
        legend: {
            position: 'top'
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (value, { seriesIndex }) => {
                    if ([1, 3, 5, 7].includes(seriesIndex)) {
                        return `$${(value / scaleFactor)?.toLocaleString()}`;
                    }
                    return `${value?.toLocaleString()}`;
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        colors: [
            '#008FFB',        // Total Active Units
            '#008FFB',        // Month's Active Revenue
            '#00E396',        // Cumulative Active Units
            '#00E396',        // Cumulative Active Revenue
            '#FF4560',        // Total Inactive Units
            '#FF7043',        // Cumulative Inactive Units (changed to distinct shade)
            '#D32F2F',        // Inactive Revenue (changed to distinct shade)
            '#B71C1C'         // Cumulative Inactive Revenue (changed to distinct shade)
        ]
    };

    const series = [
        {
            name: 'Total Active Units',
            type: 'column',
            data: totalActiveUnits
        },
        {
            name: "Month's Active Revenue",
            type: 'line',
            data: scaledTotalActiveRevenue
        },
        {
            name: 'Cumulative Active Units',
            type: 'column',
            data: cumulativeActiveUnits
        },
        {
            name: 'Cumulative Active Revenue',
            type: 'line',
            data: scaledCumulativeActiveRevenue
        },
        {
            name: 'Total Inactive Units',
            type: 'column',
            data: totalInactiveUnits
        },
        {
            name: 'Cumulative Inactive Units',
            type: 'column',
            data: cumulativeInactiveUnits
        },
        {
            name: 'Inactive Revenue',
            type: 'line',
            data: scaledInactiveRevenue
        },
        {
            name: 'Cumulative Inactive Revenue',
            type: 'line',
            data: scaledCumulativeInactiveRevenue
        }
    ];

    return (
        <div>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
            />
        </div>
    );
};

export default AggregateUnitsRevenueChart;
