import React from 'react';
import { CSSProperties } from 'react';

interface AggregateUnitsRevenueHeaderProps {
    data: {
        yearMonth: string;
        activeRevenue: number;
        totalActiveUnits: number;
        cumulativeActiveRevenue: number;
        cumulativeActiveUnits: number;
        inactiveRevenue: number;
        totalInactiveUnits: number;
        cumulativeInactiveRevenue: number;
        cumulativeInactiveUnits: number;
    }[];
}

const AggregateUnitsRevenueHeader: React.FC<AggregateUnitsRevenueHeaderProps> = ({ data }) => {
    // Calculate totals
    const totalActiveUnits = data.reduce((sum, item) => sum + item.totalActiveUnits, 0);
    const totalInactiveUnits = data.reduce((sum, item) => sum + item.totalInactiveUnits, 0);
    const totalActiveRevenue = data.reduce((sum, item) => sum + item.activeRevenue, 0);
    const totalInactiveRevenue = data.reduce((sum, item) => sum + item.inactiveRevenue, 0);
    const totalCombinedUnits = totalActiveUnits + totalInactiveUnits;
    const totalCombinedRevenue = totalActiveRevenue + totalInactiveRevenue;

    return (
        <div style={styles.container}>
            <div style={styles.row}>
                <div style={styles.column}>
                    <h2 style={styles.heading}>Total Active Units</h2>
                    <p style={styles.value}>{totalActiveUnits?.toLocaleString()}</p>
                </div>
                <div style={styles.column}>
                    <h2 style={styles.heading}>Total Active Revenue</h2>
                    <p style={styles.value}>${totalActiveRevenue?.toLocaleString()}</p>
                </div>
                <div style={styles.column}>
                    <h2 style={styles.heading}>Total Inactive Units</h2>
                    <p style={styles.inactiveValue}>{totalInactiveUnits?.toLocaleString()}</p>
                </div>
                <div style={styles.column}>
                    <h2 style={styles.heading}>Total Inactive Revenue</h2>
                    <p style={styles.inactiveValue}>${totalInactiveRevenue?.toLocaleString()}</p>
                </div>
            </div>
            <div style={styles.row}>
                <div style={styles.column}>
                    <h2 style={styles.heading}>Total Combined Units</h2>
                    <p style={styles.combinedValue}>{totalCombinedUnits?.toLocaleString()}</p>
                </div>
                <div style={styles.column}>
                    <h2 style={styles.heading}>Total Combined Revenue</h2>
                    <p style={styles.combinedValue}>${totalCombinedRevenue?.toLocaleString()}</p>
                </div>
            </div>
        </div>
    );
};

// Styles for the component
const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: '20px'
    },
    row: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    column: {
        textAlign: 'center' as const,
        flex: 1
    },
    heading: {
        fontSize: '24px',
        color: '#333',
        marginBottom: '10px'
    },
    value: {
        fontSize: '28px',
        color: '#008FFB',
        fontWeight: 'bold'
    },
    inactiveValue: {
        fontSize: '28px',
        color: '#FF4560',
        fontWeight: 'bold'
    },
    combinedValue: {
        fontSize: '28px',
        color: '#00E396',
        fontWeight: 'bold'
    }
};

export default AggregateUnitsRevenueHeader;