/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect } from "react";
// node.js library that concatenates classes (strings)
import {
  Col,
  Container,
  Row,
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader";

import { listGeneralStatistics } from "../../../actions/statistic-actions";
import isEqual from "lodash/isEqual";
import { useAuth } from "../../../hooks/useAuth";
import { PartnerContext, PartnerContextType } from "../../../contexts/partner-context";
import { arrayNotNullAndHasEntries, arrayNullOrHasNoEntries } from "../../../util/null-helper";
import RevenueDashboard from "./cards/AggregateUnitsRevenue";
import AggregateUnitsRevenue from "./cards/AggregateUnitsRevenue";

function Dashboard() {
  const [generalStatistics, setGeneralStatistics] = React.useState([]);
  const [loadingStatistics, setLoadingStatistics] = React.useState(false);
  const { partners, selectedPartnerId, setSelectedPartnerId } = React.useContext<PartnerContextType>(PartnerContext);
  const { role } = useAuth();

  const loadGeneralStatistics = useCallback(async (partnerId: string | null) => {
    setLoadingStatistics(true);
    try {
      const response = await listGeneralStatistics(partnerId);
      setGeneralStatistics(prevState => 
        isEqual(prevState, response.data) ? prevState : response.data
      );
    } catch (error) {
      console.error("Error loading general statistics:", error);
    } finally {
      setLoadingStatistics(false);
    }
  }, []); 

  const handlePartnerChange = useCallback(async (partnerId: string): Promise<void> => {
    setSelectedPartnerId(partnerId);
    await loadGeneralStatistics(partnerId);
  }, [setSelectedPartnerId, loadGeneralStatistics]); 

  const handleClearSelection = () => {
    setSelectedPartnerId(null);
    setGeneralStatistics([]);
  }

  const onPageLoad = useCallback(async () => {
        loadGeneralStatistics(null);
  }, [partners, handlePartnerChange]);

  useEffect(() => {
    onPageLoad();
    return () => {
      setSelectedPartnerId(null);
    };
  }, [onPageLoad, setSelectedPartnerId]); 

  return (
    <>
      <CardsHeader name="" parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg={12}>
            {arrayNotNullAndHasEntries(generalStatistics) ?
             <AggregateUnitsRevenue 
              data={generalStatistics} 
             /> : null}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
