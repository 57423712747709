import React from 'react';
import AggregateUnitsRevenueChart from './AggregateUnitsRevenueChart';
import AggregateUnitsRevenueHeader from './AggregateUnitsRevenueHeader';

const AggregateUnitsRevenue = ({ data }) => {
    // Initialize an object to store the cumulative revenue and units per month
    const cumulativeData = {};

    // Iterate over each partner in the data array
    data.forEach(partner => {
        // Check if the partner has the unitsRevenue property and it's not null
        if (partner.unitsRevenue) {
            // Iterate over each month's revenue data
            partner.unitsRevenue.forEach(monthData => {
                const { yearMonth, revenue, totalUnits, totalInactiveUnits, totalActiveUnits, inactiveRevenue, activeRevenue } = monthData;

                // If the month already exists in the cumulativeData object, add the revenue and units
                if (cumulativeData[yearMonth]) {
                    cumulativeData[yearMonth].totalRevenue += revenue;
                    cumulativeData[yearMonth].totalUnits += totalUnits;
                    cumulativeData[yearMonth].totalInactiveUnits += totalInactiveUnits;
                    cumulativeData[yearMonth].totalActiveUnits += totalActiveUnits;
                    cumulativeData[yearMonth].inactiveRevenue += inactiveRevenue;
                    cumulativeData[yearMonth].activeRevenue += activeRevenue;
                } else {
                    // Otherwise, initialize the month with the current revenue and units
                    cumulativeData[yearMonth] = {
                        totalRevenue: revenue,
                        totalUnits: totalUnits,
                        totalInactiveUnits: totalInactiveUnits,
                        totalActiveUnits: totalActiveUnits,
                        inactiveRevenue: inactiveRevenue,
                        activeRevenue: activeRevenue,
                    };
                }
            });
        }
    });

    // Convert the cumulativeData object into an array of objects for easier rendering
    const cumulativeDataArray = Object.keys(cumulativeData).map(yearMonth => ({
        yearMonth,
        totalRevenue: cumulativeData[yearMonth].totalRevenue,
        totalUnits: cumulativeData[yearMonth].totalUnits,
        totalInactiveUnits: cumulativeData[yearMonth].totalInactiveUnits,
        totalActiveUnits: cumulativeData[yearMonth].totalActiveUnits,
        inactiveRevenue: cumulativeData[yearMonth].inactiveRevenue,
        activeRevenue: cumulativeData[yearMonth].activeRevenue,
    }));

    // Sort the array by year and month to ensure cumulative calculation is correct
    cumulativeDataArray.sort((a, b) => {
        const [aMonth, aYear] = a.yearMonth.split('/');
        const [bMonth, bYear] = b.yearMonth.split('/');
        const aDate = new Date(`${aYear}-${aMonth}-01`);
        const bDate = new Date(`${bYear}-${bMonth}-01`);
        return aDate.getDate() - bDate.getDate();
    });

    // Find the first month with units > 0
    const firstMonthWithUnitsIndex = cumulativeDataArray.findIndex(
        monthData => monthData.totalUnits > 0
    );

    // If no months have units, return an empty array
    const filteredDataArray =
        firstMonthWithUnitsIndex === -1
            ? []
            : cumulativeDataArray.slice(firstMonthWithUnitsIndex);

    // Calculate cumulative revenue and cumulative units starting from the first month with units
    let cumulativeRevenue = 0;
    let cumulativeUnits = 0;
    let cumulativeInactiveUnits = 0;
    let cumulativeActiveUnits = 0;
    let cumulativeInactiveRevenue = 0;
    let cumulativeActiveRevenue = 0;
    const cumulativeResults = filteredDataArray.map(monthData => {
        cumulativeRevenue += monthData.totalRevenue;
        cumulativeUnits += monthData.totalUnits;
        cumulativeInactiveUnits += monthData.totalInactiveUnits;
        cumulativeActiveUnits += monthData.totalActiveUnits;
        cumulativeInactiveRevenue += monthData.inactiveRevenue;
        cumulativeActiveRevenue += monthData.activeRevenue;
        return {
            ...monthData,
            cumulativeRevenue,
            cumulativeUnits,
            cumulativeInactiveUnits,
            cumulativeActiveUnits,
            cumulativeInactiveRevenue,
            cumulativeActiveRevenue,
        };
    });

    // Pass the cumulativeResults to the chart component
    return  <div>
        <AggregateUnitsRevenueHeader data={cumulativeResults} />
        <AggregateUnitsRevenueChart data={cumulativeResults || []} />
    </div>;
};

export default AggregateUnitsRevenue;